import './RewardForm.css';

import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { callApi } from '../../../api/API';
import UserContext from '../../../contexts/userContext';

const RewardForm = ({ getAllRewardsCb, setIsRewardOpen, businessRewardType }) => {
  const { register, handleSubmit, errors, watch } = useForm();

  const { userData } = useContext(UserContext);
  const startTime = watch('startTime');
  const transactionsCount = watch('visits');

  const [selectedDays, setSelectedDays] = useState([]);

  const daysArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [rewardValues] = useState(['New Customer Welcome', 'Regular Reward']);

  const [seletedReward, setSelectedReward] = useState(null);

  const daysObjects = daysArr.map((day) => ({
    key: day.toLowerCase(),
    value: day,
  }));

  const handleSelect = (event) => {
    const clickedDay = event.target.value;
    const index = selectedDays.indexOf(clickedDay);

    if (index === -1) {
      setSelectedDays([...selectedDays, clickedDay]); // Select the day
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== clickedDay)); // Deselect the day
    }
  };

  const usaTimeZones = [
    'Pacific/Honolulu',
    'America/Anchorage',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Indiana/Indianapolis',
  ];

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 60) {
        const formattedHour = (hour % 12 === 0 ? 12 : hour % 12).toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const period = hour < 12 ? 'AM' : 'PM';
        times.push(`${formattedHour}:${formattedMinute} ${period}`);
      }
    }
    return times;
  };

  const parseTime = (time) => {
    const [timePart, period] = time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  };

  const isDisabled = (endTime) => {
    if (!startTime) return false;
    return parseTime(endTime) <= parseTime(startTime);
  };

  // create reward api
  const handleSubmitForm = async (payload) => {
    if (!payload.startTime) delete payload.startTime;
    if (!payload.endTime) delete payload.endTime;
    if (!payload.timeZone) delete payload.timeZone;
    if (!payload.activeDays) delete payload.activeDays;
    if (!payload.interval) payload.interval = "1";

    const create = await callApi('/rewards', 'POST', payload, 'Bearer ' + userData?.token);
    if (create) {
      getAllRewardsCb();
      setIsRewardOpen(false);
      setTimeout(() => {}, [2000]);
    } else {
    }
  };

  console.log('selected', seletedReward);

  return (
    // reward creation form
    <Form
      autoComplete='off'
      className='position-absolute w-100 search-block'
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <Form.Group>
        <Form.Control
          autoComplete='off'
          name='title'
          ref={register({ required: true })}
          type='text'
          className={`input ${errors.title ? 'is-invalid' : ''} shadow-none`}
          placeholder='Title'
        />
        {errors.title && <p style={{ color: 'red' }}>Title is required</p>}
      </Form.Group>
      <Form.Group>
        <Form.Control
          autoComplete='off'
          name='description'
          ref={register({ required: true })}
          type='text'
          className={`input ${errors.description ? 'is-invalid' : ''} shadow-none`}
          placeholder='Description'
        />
        {errors.description && <p style={{ color: 'red' }}>Description is required</p>}
      </Form.Group>

      {/* <Form.Group>
        <Form.Control
          as='select'
          autoComplete='off'
          name='status'
          ref={register({ required: true })}
          className={`input ${errors.status ? 'is-invalid' : ''} shadow-none`}
        >
          <option value='Active'>Active</option>
          <option value='Inactive'>Inactive</option>
        </Form.Control>
        {errors.status && <p style={{ color: 'red' }}>Status is required</p>}
      </Form.Group> */}

      <Form.Group>
        <Form.Control
          autoComplete='off'
          name='interval'
          ref={register({ required: transactionsCount < 2 && seletedReward === "New Customer Welcome" ? false : true, min: 1 })}
          type='number'
          className={`input ${errors.interval ? 'is-invalid' : ''} shadow-none`}
          placeholder='Interval (in hrs.)'
        />
        {errors.interval && errors.interval.type === 'required' && (
          <p style={{ color: 'red' }}>Interval is required</p>
        )}
        {errors.interval && errors.interval.type === 'min' && (
          <p style={{ color: 'red' }}>Interval should be at least 1</p>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Control
          autoComplete='off'
          name='visits'
          ref={register({ required: true, min: 0 })}
          type='number'
          className={`input ${errors.visits ? 'is-invalid' : ''} shadow-none`}
          placeholder='No. of transactions'
        />
        {errors.visits && errors.visits.type === 'required' && (
          <p style={{ color: 'red' }}>Transactions are required</p>
        )}
        {errors.visits && errors.visits.type === 'min' && (
          <p style={{ color: 'red' }}>Transactions should be atleast 0</p>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Control
          autoComplete='off'
          name='minimumBillingAmount'
          ref={register({ required: true, min: 1 })}
          type='number'
          className={`input ${errors.amount ? 'is-invalid' : ''} shadow-none`}
          placeholder='Min billing amount'
        />
        {errors.minimumBillingAmount && errors.minimumBillingAmount.type === 'required' && (
          <p style={{ color: 'red' }}>Amount is required</p>
        )}
        {errors.minimumBillingAmount && errors.minimumBillingAmount.type === 'min' && (
          <p style={{ color: 'red' }}>Amount should be greater than 1</p>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Control
          autoComplete='off'
          name='discountPercentage'
          ref={register({ required: true, min: 1 })}
          type='number'
          className={`input ${errors.amount ? 'is-invalid' : ''} shadow-none`}
          placeholder='Discount %age'
        />
        {errors.discountPercentage && errors.discountPercentage.type === 'required' && (
          <p style={{ color: 'red' }}>Discount %age is required</p>
        )}
        {errors.discountPercentage && errors.discountPercentage.type === 'min' && (
          <p style={{ color: 'red' }}>Discount should be greater than 1</p>
        )}
      </Form.Group>

      {/* <Form.Group>
        <Form.Control
          as='select'
          multiple
          autoComplete='off'
          name='activeDays'
          ref={register({ required: false })}
          onChange={handleSelect}
          className={`input ${errors.activeDays ? 'is-invalid' : ''} shadow-none`}
          value={selectedDays}
          placeholder='Active days'
        >
          <option disabled>Select active days</option>
          {daysObjects.map((day) => (
            <option key={day?.key} value={day?.key}>
              {day?.value}
            </option>
          ))}
        </Form.Control>
        {errors.activeDays && <p style={{ color: 'red' }}>Days are required</p>}
      </Form.Group>

      <Form.Group>
        <Form.Control
          as='select'
          name='startTime'
          ref={register({ required: false })}
          className={`input ${errors.startTime ? 'is-invalid' : ''} shadow-none`}
        >
          <option value=''>Select start time</option>
          {generateTimeOptions().map((time) => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </Form.Control>
        {errors.startTime && errors.startTime.type === 'required' && (
          <p style={{ color: 'red' }}>Start time is required</p>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Control
          as='select'
          name='endTime'
          ref={register({
            required: false,
            validate: (endTime) => !isDisabled(endTime),
          })}
          className={`input ${errors.endTime ? 'is-invalid' : ''} shadow-none`}
        >
          <option value=''>Select end time</option>
          {generateTimeOptions().map((time) => (
            <option key={time} value={time} disabled={isDisabled(time)}>
              {time}
            </option>
          ))}
        </Form.Control>
        {errors.endTime && errors.endTime.type === 'required' && (
          <p style={{ color: 'red' }}>End time is required</p>
        )}
        {errors.endTime && errors.endTime.type === 'validate' && (
          <p style={{ color: 'red' }}>End time must be later than start time</p>
        )}
      </Form.Group> */}

      <Form.Group>
        <Form.Control
          as='select'
          autoComplete='off'
          name='timeZone'
          ref={register({ required: false })}
          className={`input ${errors.timeZone ? 'is-invalid' : ''} shadow-none`}
        >
          <option value=''>Select TimeZone</option>
          {usaTimeZones.map((timeZone) => (
            <option key={timeZone} value={timeZone}>
              {timeZone}
            </option>
          ))}
        </Form.Control>
        {errors.timeZone && <p style={{ color: 'red' }}>Time zone is required</p>}
      </Form.Group>

      <Form.Group>
        <Form.Control
          autoComplete='off'
          name='maxDiscountAmount'
          ref={register({ required: true, min: 1 })}
          type='number'
          className={`input ${errors.amount ? 'is-invalid' : ''} shadow-none`}
          placeholder='Max discount amount'
        />
        {errors.maxDiscountAmount && errors.maxDiscountAmount.type === 'required' && (
          <p style={{ color: 'red' }}>Max Discoint amount is required</p>
        )}
        {errors.maxDiscountAmount && errors.maxDiscountAmount.type === 'min' && (
          <p style={{ color: 'red' }}>Amount should be greater than 1</p>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Control
          as='select'
          autoComplete='off'
          name='rewardType'
          ref={register({ required: true })}
          className={`input ${errors.timeZone ? 'is-invalid' : ''} shadow-none`}
          onChange={(e) => setSelectedReward(e.target.value)}
        >
          <option value=''>Select reward type</option>
          {rewardValues?.map((val) => (
            <option
              value={val}
              className={`${businessRewardType?.includes(val) ? 'disabled-option' : ''}`}
              disabled={businessRewardType?.includes(val)}
            >
              {val}
            </option>
          ))}
        </Form.Control>
        {errors.rewardType && <p style={{ color: 'red' }}>Reward type is required</p>}
      </Form.Group>

      {/* <Form.Group>
        <Form.Control
          autoComplete='off'
          name='bonus'
          // ref={register({ required: true, min: 1 })}
          type='number'
          className={`input ${errors.amount ? 'is-invalid' : ''} shadow-none`}
          placeholder='Bonus'
        />
        {errors.bonus && errors.bonus.type === 'required' && (
          <p style={{ color: 'red' }}>Bonus is required</p>
        )}
        {errors.bonus && errors.bonus.type === 'min' && (
          <p style={{ color: 'red' }}>Amount should be greater than 1</p>
        )}
      </Form.Group> */}

      <Form.Group>
        <Button
          style={{ width: '100%' }}
          variant='link'
          className='text-decoration-none shadow-none settings mt-2'
          type='submit'
        >
          Add
        </Button>
      </Form.Group>
    </Form>
  );
};

export default RewardForm;
